"use client";
import Link from "next/link";
import Image from "next/image";
import { usePathname } from "next/navigation";
import VideoDuration from "@/app/components/atoms/VideoDuration/VideoDuration";
import styles from "@/app/styles/Cards/CardVideoNews.module.scss";
import { WindowWithDataLayer } from "@/app/types/WindowDataLayer";
import handleDataLayerOnClick from "@/app/helpers/analytics/handleDataLayerOnClick";
import { upperCamelCase } from "@/app/helpers/upperCamelCase/upperCamelCase";
declare const window: WindowWithDataLayer;

const CardTheme = ({ card, isDark, blockType }: CardThemeProps) => {
	const pathName = usePathname();
	const imgSizes = `
		(max-width: 414px) 62.8vw,
		(max-width: 1280px) 50vw,
		(max-width: 1440px) 18.05vw
	`;
	function safeDestructure(obj: any, path: any) {
		const value = path
			.split(".")
			.reduce((acc: any, prop: any) => acc && acc[prop], obj);
		return value !== undefined ? value : null;
	}

	const corte_16_9 = safeDestructure(
		card,
		"relationships.field_imagen_story.attributes.image_style_uri.corte_16_9"
	);
	const imagesrc = card?.isVideo
		? card?.imageVideo?.image
		: card?.imageVideo?.image
		? card?.imageVideo?.image
		: corte_16_9;
		
	const handleBannerClick = (contentName: string) => {
		const blockDataLayer = {
			event: "select_content",
			content_type: "SP-Card",
			seccion: pathName,
			content_name: contentName,
			eventAction: "click",
			tipoBloque: upperCamelCase(blockType) || undefined,
			tipoElemento: "LinkText",
			tituloBloque: card?.title || undefined,
		};
		handleDataLayerOnClick(blockDataLayer, 0, pathName);
	};
	return (
		<figure
			className={
				isDark
					? `${styles?.fig_card_vod} ${styles?.fig_card_vod__theme} ${styles?.dark}`
					: `${styles?.fig_card_vod} ${styles?.fig_card_vod__theme}`
			}
		>
			<Link
				href={card?.path ?? {}}
				title={card?.title}
				prefetch={false}
				onClick={() => handleBannerClick(card?.title)}
			>
				<div className={styles?.fig_card_vod_img_wrapper}>
					<Image
						src={imagesrc ?? "/assets/placeholder_.svg"}
						alt={
							card?.imageVideo?.alt || card?.thumbnail?.alt || "Imagen default"
						}
						width={card?.thumbnail?.width || 325}
						height={card?.thumbnail?.height || 182}
						sizes={imgSizes}
						quality={50}
					/>
					{card?.isVideo && (
						<VideoDuration
							duration={card?.imageVideo?.duration}
							isPlay={false}
						/>
					)}
				</div>
				<figcaption className={styles?.fig_card_vod_caption}>
					<h3>{card?.title}</h3>
				</figcaption>
			</Link>
		</figure>
	);
};
type CardThemeProps = {
	card: any;
	isDark?: any;
	blockType?: any;
};
export default CardTheme;
