"use client";
import React, { useState } from "react";
import PageViewEvent from "@/app/lib/PageViewEvent";
import { usePathname } from "next/navigation";
import style from "@/app/styles/Blocks/TaxonomyThemes.module.scss";
import CardTheme from "@/app/components/molecules/cards/CardTheme/CardTheme";
import BoxAds from "@/app/components/atoms/AdContainer/BoxAds";
import AsideAds from "@/app/components/atoms/AdContainer/AsideAds";
import Icon from "@/app/components/atoms/Icon/Icon";
import GetLandingThemes from "@/app/queries/getTaxonomyThemes";
import { print } from "graphql";

import { WindowWithDataLayer } from "@/app/types/WindowDataLayer";
import handleDataLayerOnClick from "@/app/helpers/analytics/handleDataLayerOnClick";
import { upperCamelCase } from "@/app/helpers/upperCamelCase/upperCamelCase";

declare const window: WindowWithDataLayer;

export default function LPThemes({
	data,
	id,
	type,
	title,
	hasAds,
	contentAds,
	totalItems,
	isDark = false,
	dlData,
}: any) {
	const cards: any[] = data?.getLandingThemes?.items ?? [];
	const [maxCards, setMaxCards] = useState<number>(48);
	const [currentPage, setCurrentPage] = useState<number>(totalItems);
	const [activeAds] = useState(!hasAds);
	const [totalElements, setTotalElements] = useState(cards?.length || 0);
	const [loading, setLoading] = useState(false);
	const [currentThemeList, setCurrentThemeList] = useState<any>(cards ?? []);
	const [themeList, setThemeList] = useState<any>([]);
	const pathName = usePathname();
	const blockType = type?.replace("taxonomy_term--", "")?.split("_")?.join(" ");
	const handleBannerClick = (contentName: string) => {
		const blockDataLayer = {
			event: "select_content",
			content_type: "Button",
			seccion: pathName,
			content_name: contentName,
			eventAction: "click",
			tipoBloque: upperCamelCase(blockType) || undefined,
			tipoElemento: "LinkText",
			tituloBloque: title || undefined,
		};
		handleDataLayerOnClick(blockDataLayer, 0, pathName);
	};

	let currentPosition = 2;

	const getMoreItems = () => {
		handleBannerClick("Más sobre el tema");
		setCurrentPage(currentPage + totalItems);
		setLoading(true);
		const reqheaders = new Headers();
		reqheaders.append("Content-Type", "application/json");
		reqheaders.append("X-Secure-Environment", `${process.env.NEXT_PUBLIC_APOLLOSEC}`);
		const graphReq = JSON.stringify({
			query: print(GetLandingThemes),
			variables: {
				getLandingThemesId: id,
				page: currentPage,
				totalItems,
			},
		});
		const reqOpts = {
			method: "POST",
			headers: reqheaders,
			body: graphReq,
			next: { revalidate: 5 },
		};
		const endpoint = process.env.NEXT_PUBLIC_APOLLO_SERVER_URL ?? "";
		fetch(endpoint, reqOpts)
			.then((res) => {
				if (res?.status === 200) return res?.json();
				return null;
			})
			.then((data) => {
				const uniqueTitles = new Set([
					...currentThemeList?.map((theme: any) => theme?.title),
					...themeList?.map((theme: any) => theme?.title),
				]);

				const uniqueThemes = data?.data?.getLandingThemes?.items?.filter(
					(theme: any) => {
						return !uniqueTitles?.has(theme?.title);
					}
				);
				setTotalElements(uniqueThemes?.length);
				setThemeList((prevThemeList: any) => [
					...prevThemeList,
					...uniqueThemes,
				]);
				setLoading(false);
				themeList?.length === 0 ?? setMaxCards(currentThemeList?.length);
			})
			.catch((err) => {
				return {
					data: null,
					error: err,
				};
			});
	};

	return (
		<section className={`${style["themes__sectionWrapper"]}`}>
			<PageViewEvent metadata={dlData} />
			<div className={`${style["themes__wrapper"]}`}>
				<div
					className={`
						${style["themes__cardsWrapper"]}
						${
							activeAds
								? style["themes__cardsWrapper--withAd"]
								: style["themes__cardsWrapper--withoutAd"]
						}
					`}
				>
					{currentThemeList.map((card: any, index: number) => (
						<React.Fragment key={`card-${index}`}>
							<CardTheme card={card} blockType={blockType} />
							{(index + 1) % contentAds === 0 && activeAds && (
								<BoxAds withAd={activeAds} position={currentPosition++} />
							)}
						</React.Fragment>
					))}
					{themeList.map((card: any, index: number) => (
						<React.Fragment key={`card-${index}`}>
							<CardTheme card={card} blockType={blockType} />
							{(index + 1) % contentAds === 0 && activeAds && (
								<BoxAds withAd={activeAds} position={currentPosition++} />
							)}
						</React.Fragment>
					))}
					{currentThemeList.length < 6 ||
						(themeList.length < maxCards &&
							Boolean(totalElements) &&
							!(totalElements < 6) && (
								<button
									className={`
									${style["themes__loadButton"]}
									${!activeAds ? style["themes__loadButton--withoutAd"] : ""}
								`}
									type="button"
									onClick={getMoreItems}
									disabled={loading}
								>
									{loading ? "Cargando más sobre el tema" : "Más sobre el tema"}
									<Icon name="ArrowDown" />
								</button>
							))}
				</div>
				{activeAds && <AsideAds withAd={activeAds} />}
			</div>
		</section>
	);
}
