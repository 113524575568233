"use client";
import { useResponsive } from "@/app/hooks/useResponsive";
import { usePathname } from "next/navigation";
import Advertising from "@/app/components/atoms/AdContainer/Advertising";
import style from "@/app/styles/Atoms/Advertising.module.scss";
import indexAds from "@/app/components/atoms/Ads/Ads";

const AsideAds = ({ withAd }: any) => {
	const { isDesktop } = useResponsive();
	const pathName = usePathname() || "";
	return (
		<>
			{isDesktop && (
				<div
					className={`
						${style["advertising__asideAd"]}
						${withAd ? style["advertising__asideAd--withAd"] : ""}
					`}
				>
					<Advertising typeAd={indexAds.desktop.combo3} urlPath={pathName} />
				</div>
			)}
		</>
	);
};

export default AsideAds;
