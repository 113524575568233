import { gql } from "@apollo/client";

export default gql`
	query GetLandingThemes(
		$getLandingThemesId: String!
		$totalItems: Int!
		$page: Int!
	) {
		getLandingThemes(
			id: $getLandingThemesId
			totalItems: $totalItems
			page: $page
		) {
			field_bloque_inferior {
				data {
					type
					id
					position
					total
				}
			}
			field_bloque_superior {
				data {
					type
					id
					position
					total
				}
			}
			items {
				title
				path
				isVideo
				imageVideo {
					image
					alt
					duration
				}
				thumbnail {
					caption
					alt
					width
					height
				}
				relationships {
					field_imagen_story {
						attributes {
							image_style_uri {
								corte_16_9
							}
						}
					}
				}
			}
		}
	}
`;
